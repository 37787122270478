import type { Dayjs, PluginFunc } from "dayjs";
import dayjs from "dayjs";

const REGEX_TIMEZONE_OFFSET_FORMAT = /^(.*)([+-])(\d{2}):(\d{2})|(Z)$/;

/**
 * @see https://github.com/iamkun/dayjs/issues/651#issuecomment-763033265
 * decorates dayjs in order to keep the utcOffset of the given date string
 * natively dayjs auto-converts to local time & losing utcOffset info.
 */
const pluginFunc: PluginFunc<unknown> = (option: unknown, dayjsClass: typeof Dayjs, dayjsFactory: typeof dayjs) => {
  dayjsFactory.parseZone = (date?: dayjs.ConfigType, format?: dayjs.OptionType, locale?: string, strict?: boolean) => {
    const formatObj = typeof format === "string" ? { format } : format;
    if (typeof date !== "string") {
      return dayjs(date, formatObj, locale, strict);
    }
    const match = date.match(REGEX_TIMEZONE_OFFSET_FORMAT);
    if (match === null) {
      return dayjs();
    }
    if (match[0] === "Z") {
      return dayjs(
        date,
        {
          utc: true,
          ...formatObj,
        },
        locale,
        strict,
      );
    }
    const [, dateTime, sign, tzHour, tzMinute] = match;
    const uOffset: number = Number.parseInt(tzHour, 10) * 60 + Number.parseInt(tzMinute, 10);
    const offset = sign === "+" ? uOffset : -uOffset;

    return dayjs(
      dateTime,
      {
        $offset: offset,
        ...formatObj,
      } as unknown as dayjs.OptionType,
      locale,
      strict,
    );
  };
};

export default pluginFunc;

declare module "dayjs" {
  function parseZone(
    date?: dayjs.ConfigType,
    format?: dayjs.OptionType,
    locale?: string,
    strict?: boolean,
  ): dayjs.Dayjs;
}
